// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//* Variable Declaration
const region = 'us-gov-west-1';
const apiUrl = 'https://9on3pvvnu6.execute-api.us-gov-west-1.amazonaws.com/test';
const tempUrl = 'https://i20u8jnn08.execute-api.us-gov-west-1.amazonaws.com/test';
const state = 'az';
const city = 'globe';
const agencytpe = 'court';
const envName = 'test';

export const environment = {
  production: false,
  apiUrl: apiUrl,
  awsConf: {
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-gov-west-1:158a6a75-e118-4335-ba5a-3416ced317db',

      // REQUIRED - Amazon Cognito Region
      region: region,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-gov-west-1_tZm2GNiQe',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '1evkennanle2gbsn4944um6t83',
    },
    Storage: {
      bucket: `${state}-${city}-${agencytpe}-${envName}-ewarrant-uploads`,
      region: region,
    },
    API: {
      endpoints: [
        {
          name: 'cwApi',
          endpoint: apiUrl,
          region: region,
        },
        {
          name: 'tempApi',
          endpoint: tempUrl,
          region: region,
        },
      ],
    },
  },
  // can be ERROR | WARN | INFO | DEBUG | VERBOSE
  awsLogLevel: 'DEBUG',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
